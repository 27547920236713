import React from "react"

import LayoutLanding from "../components/layout-landing"
import SEO from "../components/seo"
import ClientOnly from "../components/client-only"


import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../aws-exports';

class ConfirmationPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { order: null, errorLoading: false };
    
    const params = new URLSearchParams(this.props.location.search);
    if(!params){
      console.error('Could not create URLSearchParams on confirmation page');
    }

    const id = params ? params.get("id") : null;
    const key = params ? params.get("key") : null;

    if (!key || !id) {
      this.goToFrontPage()
    };
    API.configure(config);
    this.getConfirmation(key, id);
  }

  getConfirmation = (key, id) => {

    const query = `query GetOrderConfirmation($key: String, $id: String) {
      getOrderConfirmation(key: $key, id: $id) {
        id
        createdAt
        name
        email
        streetAddress
        postalNumber
        postalArea
        phone
        productName
        quantity
        price
        partnerName
        elegibleName
        totalPrice
        totalDiscount
      }
    }`;

    API.graphql(graphqlOperation(query, { key: key, id: id }))
      .then(result => {
        this.setState(prevState => ({
          order: result.data.getOrderConfirmation,
          errorLoading: false
        }));

        if (!this.state.order) this.goToFrontPage();
         
      })
      .catch(e => {
        console.log('ERROR LOOKING UP CONFIRMATION');
        var logger = _LTracker || [];
        logger.push("Failed to load confirmation page for key=" + key + " and id=" + id);

        if(e) {
          console.error(e.message);
          logger.push(e);
          logger.push(e.message);
        }
        this.setState(prevState => ({
          order: null,
          errorLoading: true
        }))
      });

  }

  goToFrontPage() {
    if (typeof window !== 'undefined') { // window is not available at build time
      window.location.href = "/?error=invalid-confirmation";
    }
  }


  render() {

    return (
      <LayoutLanding>
        <SEO title="Leirskoledagboken AS - Ordrebekreftelse" />

        <div className="row">

          <div className="col">

            <ClientOnly>

              {!this.state.order && !this.state.errorLoading ?
                <div className="spinner-border" role="status">
                  <span className="sr-only">Laster ordrebekreftelsen...</span>
                </div>
                : null
              }
            </ClientOnly>

            <ClientOnly>
              {this.state.errorLoading ?
                <div className="alert alert-danger" role="alert">Ops! Vi beklager, men noe gikk galt når vi skulle hente ordre bekreftelsen. Vennligst prøv å last siden på nytt eller sjekk om du har mottatt e-post kvittering.</div>
                : null
              }
            </ClientOnly>

            {this.state.order ?
              <div>
                <div>
                  <h1>Ordrebekreftelse for {this.state.order.id.substring(0, 8)} </h1>
                </div>
                <div>
                  <p>Takk for din bestilling {this.state.order.quantity} stk av {this.state.order.productName} for totalt kr {this.state.order.totalPrice} inkl porto
                  {this.state.order.totalDiscount>0 ? (' og rabatt på ' + this.state.order.totalDiscount.toString() + ' kr')  : ''}.</p>
                  <p>Ordrebekreftelsen er også sendt til {this.state.order.email}. Dersom du ikke mottar den innen kort tid, sjekk om den ligger i søppelpost/spam.</p>
                  
                </div>
                <div className="d-flex justify-content-center">
                  <dl className="row ">
                    <dt className="col-sm-6 text-sm-right">Bestillers navn:</dt>
                    <dd className="col-sm-6 text-sm-left">{this.state.order.name}</dd>
                    {this.state.order.phone ?
                      <dt className="col-sm-6  text-sm-right">Telefon&nbsp;nr:</dt>
                      : null}

                    {this.state.order.phone ?
                      <dd className="col-sm-6 text-sm-left">{this.state.order.phone}</dd>
                      : null}
                    <dt className="col-sm-6 text-sm-right">E-post:</dt>
                    <dd className="col-sm-6 text-sm-left">{this.state.order.email}</dd>

                    <dt className="col-sm-6 text-sm-right">Gateaddresse:</dt>
                    <dd className="col-sm-6 text-sm-left">{this.state.order.streetAddress}</dd>

                    <dt className="col-sm-6 text-sm-right">Postnr. og sted:</dt>
                    <dd className="col-sm-6 text-sm-left">{this.state.order.postalNumber} {this.state.order.postalArea}</dd>

                  </dl>

                </div>
                <div>
                  <p>
                    Du vil få tilsendt boken straks den er ferdig trykket med 30 dagers fri returrett. Se følgearket for returinfo. Faktura blir sendt på e-post etter boken er sendt, 30 dagers betalingsfrist.
                  </p>
                </div>
              </div>
              :
              null
            }
          </div>
        </div>
      </LayoutLanding>)
  }
}

export default ConfirmationPage
